export default function useAuthState() {
  const state = useState<UserAuthStateTypes>('auth-state')
  const { LOGIN, REGISTER, REGISTER_ACCOUNT_CREATED, RESET_PASSWORD } =
    USER_AUTH_STATE

  const { t } = useI18n()

  const titleMap: { [key in UserAuthStateTypes]?: string } = {
    [LOGIN]: t('login'),
    [REGISTER]: t('createAccount'),
    [RESET_PASSWORD]: t('resetPassword'),
  }
  const title = computed(() => titleMap[state.value] ?? '')

  const showNoAccountFooter = computed(
    () => state.value === LOGIN || state.value === RESET_PASSWORD
  )

  const showCheckSpamFooter = computed(
    () => state.value === REGISTER_ACCOUNT_CREATED
  )

  function changeState(newState: UserAuthStateTypes) {
    state.value = newState
  }

  return {
    state,
    title,
    showNoAccountFooter,
    showCheckSpamFooter,
    changeState,
  }
}
