export const PAGE_HEADING_TAG = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
} as const

export const PAGE_GRID_LAYOUT = {
  COLS_1: '1',
  COLS_1_1: '1/1',
  COLS_1_2: '1/2',
  COLS_2_1: '2/1',
  COLS_1_1_1: '1/1/1',
  COLS_1_2_1: '1/2/1',
  COLS_2_1_1: '2/1/1',
  COLS_1_1_2: '1/1/2',
  COLS_1_1_1_1: '1/1/1/1',
  COLS_1_1_1_1_1: '1/1/1/1/1',
} as const

export const PAGE_GRID_GAP_SIZE = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export const PAGE_GRID_VERTICAL_ALIGN = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
} as const

export const PAGE_GRID_COLUMN_HORIZONTAL_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const
