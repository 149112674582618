export const IMAGE_CLIP = {
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER: 'center',
  LEFT: 'left',
  LEFT_TOP: 'left-top',
  LEFT_BOTTOM: 'left-bottom',
  RIGHT: 'right',
  RIGHT_TOP: 'right-top',
  RIGHT_BOTTOM: 'right-bottom',
} as const

export const IMAGE_LOADING = {
  EAGER: 'eager',
  LAZY: 'lazy',
} as const

export const IMAGE_FIT = {
  FIT: 'fit',
  STRETCH: 'stretch',
  CROP: 'cover',
  INSIDE: 'inside',
  OUTSIDE: 'outside',
  FILL: 'fill',
  COVER: 'cover',
} as const

export const IMAGE_FILE_FORMAT = {
  WEBP: 'webp',
  PNG: 'png',
} as const

export const IMAGE_FORMAT = {
  SQUARE: 'square',
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  WIDESCREEN: 'widescreen',
} as const

export const IMAGE_MODIFIER = {
  ORIENTATION: 'or',
  FLIP: 'flip',
  CROP: 'crop',
  WIDTH: 'width',
  HEIGHT: 'height',
  FIT: 'fit',
  DPR: 'dpr',
  BRIGHTNESS: 'bri',
  CONTRAST: 'con',
  GAMMA: 'gam',
  SHARPEN: 'sharp',
  BLUR: 'blur',
  PIXEL: 'pixel',
  FILTER: 'filt',
  QUALITY: 'quality',
  FORMAT: 'fm',
} as const
