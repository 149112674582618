<template>
  <div
    data-page-grid
    :data-default-horizontal-spacing="defaultHorizontalSpacing"
    class="group grid grid-cols-1"
    :class="[
      gridGapClassList,
      gridColsClassList,
      {
        'h-full': height !== PAGE_CONTAINER_HEIGHT.FLEXIBLE,
        'items-start': verticalAlign === PAGE_GRID_VERTICAL_ALIGN.TOP,
        'items-center': verticalAlign === PAGE_GRID_VERTICAL_ALIGN.CENTER,
        'items-end': verticalAlign === PAGE_GRID_VERTICAL_ALIGN.BOTTOM,
      },
    ]"
  >
    <slot :col-spacing="innerColSpacingClassList" />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  defaultHorizontalSpacing?: boolean
  height?: PageContainerHeight
  verticalAlign?: PageGridVerticalAlign
  gap?: {
    default?: PageGridGapSize
    sm?: PageGridGapSize
    md?: PageGridGapSize
    lg?: PageGridGapSize
  }
  layout?: {
    sm?: PageGridLayout
    md?: PageGridLayout
    lg?: PageGridLayout
  }
}

const props = withDefaults(defineProps<Props>(), {
  height: PAGE_CONTAINER_HEIGHT.FLEXIBLE,
  defaultHorizontalSpacing: true,
  verticalAlign: undefined,
  horizontalAlign: undefined,
  gap: () => ({
    default: PAGE_GRID_GAP_SIZE.SM,
    sm: PAGE_GRID_GAP_SIZE.SM,
    md: PAGE_GRID_GAP_SIZE.SM,
    lg: PAGE_GRID_GAP_SIZE.SM,
  }),
  layout: () => ({
    sm: PAGE_GRID_LAYOUT.COLS_1,
    md: PAGE_GRID_LAYOUT.COLS_1,
    lg: PAGE_GRID_LAYOUT.COLS_1,
  }),
})

const {
  COLS_1,
  COLS_1_1,
  COLS_1_2,
  COLS_2_1,
  COLS_1_1_1,
  COLS_1_2_1,
  COLS_2_1_1,
  COLS_1_1_2,
  COLS_1_1_1_1,
  COLS_1_1_1_1_1,
} = PAGE_GRID_LAYOUT

const { SM, MD, LG } = PAGE_GRID_GAP_SIZE

const gridGapClassList = computed(() => ({
  'gap-3': props.gap?.default === SM,
  'gap-6': props.gap?.default === MD || !props.gap?.default,
  'gap-12': props.gap?.default === LG,
  'sm:gap-3': props.gap?.sm === SM,
  'sm:gap-6': props.gap?.sm === MD,
  'sm:gap-12': props.gap?.sm === LG,
  'md:gap-3': props.gap?.md === SM,
  'md:gap-6': props.gap?.md === MD,
  'md:gap-12': props.gap?.md === LG,
  'lg:gap-3': props.gap?.lg === SM,
  'lg:gap-6': props.gap?.lg === MD,
  'lg:gap-12': props.gap?.lg === LG,
}))

const gridColsClassList = computed(() => {
  const { sm, md, lg } = props.layout ?? {}

  return {
    'sm:grid-cols-1': sm === COLS_1,
    'sm:grid-cols-2': sm === COLS_1_1,
    'sm:grid-cols-[33.33333%,1fr]': sm === COLS_1_2,
    'sm:grid-cols-[66.66667%,1fr]': sm === COLS_2_1,
    'sm:grid-cols-3': sm === COLS_1_1_1,
    'sm:grid-cols-[25%,1fr,25%]': sm === COLS_1_2_1,
    'sm:grid-cols-[1fr,25%,25%]': sm === COLS_2_1_1,
    'sm:grid-cols-[25%,25%,1fr]': sm === COLS_1_1_2,
    'sm:grid-cols-4': sm === COLS_1_1_1_1,
    'sm:grid-cols-5': sm === COLS_1_1_1_1_1,

    'md:grid-cols-1': sm === COLS_1,
    'md:grid-cols-2': md === COLS_1_1,
    'md:grid-cols-[33.33333%,1fr]': md === COLS_1_2,
    'md:grid-cols-[66.66667%,1fr]': md === COLS_2_1,
    'md:grid-cols-3': md === COLS_1_1_1,
    'md:grid-cols-[25%,1fr,25%]': md === COLS_1_2_1,
    'md:grid-cols-[1fr,25%,25%]': md === COLS_2_1_1,
    'md:grid-cols-[25%,25%,1fr]': md === COLS_1_1_2,
    'md:grid-cols-4': md === COLS_1_1_1_1,
    'md:grid-cols-5': md === COLS_1_1_1_1_1,

    'lg:grid-cols-1': sm === COLS_1,
    'lg:grid-cols-2': lg === COLS_1_1,
    'lg:grid-cols-[33.33333%,1fr]': lg === COLS_1_2,
    'lg:grid-cols-[66.66667%,1fr]': lg === COLS_2_1,
    'lg:grid-cols-3': lg === COLS_1_1_1,
    'lg:grid-cols-[25%,1fr,25%]': lg === COLS_1_2_1,
    'lg:grid-cols-[1fr,25%,25%]': lg === COLS_2_1_1,
    'lg:grid-cols-[25%,25%,1fr]': lg === COLS_1_1_2,
    'lg:grid-cols-4': lg === COLS_1_1_1_1,
    'lg:grid-cols-5': lg === COLS_1_1_1_1_1,
  }
})

const innerColSpacingClassList = computed(() => ({
  'space-y-3': props.gap?.default === SM,
  'space-y-6': props.gap?.default === MD || !props.gap?.default,
  'space-y-12': props.gap?.default === LG,
  'sm:space-y-3': props.gap?.sm === SM,
  'sm:space-y-6': props.gap?.sm === MD,
  'sm:space-y-12': props.gap?.sm === LG,
  'md:space-y-3': props.gap?.md === SM,
  'md:space-y-6': props.gap?.md === MD,
  'md:space-y-12': props.gap?.md === LG,
  'lg:space-y-3': props.gap?.lg === SM,
  'lg:space-y-6': props.gap?.lg === MD,
  'lg:space-y-12': props.gap?.lg === LG,
}))

defineOptions({
  name: 'PageGrid',
})
</script>
