<template>
  <PageContainer :as="PAGE_CONTAINER_TAG.ARTICLE" class="space-y-4">
    <template v-if="order">
      <OrderWidget v-if="order?.type !== 'EVENT'" :order="order" />
      <OrderEvent v-else :order="order" />
    </template>
  </PageContainer>
</template>

<script lang="ts" setup>
const route = useRoute()
const { t } = useI18n()
const { fetchOrder, order, purchaseData } = useOrder()

await fetchOrder({
  orderNumber: route.params.orderNumber as string,
  securityKey: route.params.securityKey as string,
})

const { $gtm } = useNuxtApp()
onMounted(() => {
  $gtm.trackPageView()
  $gtm.push({
    event: 'purchase',
    ecommerce: {
      purchase: purchaseData.value,
    },
  })
})

if (!order.value) {
  showError({
    statusCode: 404,
    statusMessage: 'pageNotFound',
  })
}

usePageMetas({
  title: `${t('pageTitle.order')} ${order.value.orderNumber}`,
})

defineI18nRoute({
  paths: {
    en: '/account/order/[orderNumber]/[securityKey]',
    de: '/konto/bestellung/[orderNumber]/[securityKey]',
    es: '/cuenta/compra/[orderNumber]/[securityKey]',
  },
})

defineOptions({
  name: 'AccountOrder',
})
</script>

<i18n>
de:
  pageTitle:
    order: "Bestellung"
es:
  pageTitle:
    order: "Orden"
</i18n>
