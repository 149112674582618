import type { AsyncDataRequestStatus } from 'nuxt/dist/app/composables/asyncData'

export const GQL_STATUS_VALUES: {
  [key in string]: AsyncDataRequestStatus
} = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
} as const
