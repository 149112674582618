interface NestedObject {
  [key: string]: any
}

export default function resolve(
  object: NestedObject,
  path: string,
  defaultValue: any = null
): any | null {
  const result = path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter((s) => s)
    .reduce(
      (acc: NestedObject | undefined, val: string) => acc && acc[val],
      object
    )

  return typeof result !== 'undefined' ? result : defaultValue
}
