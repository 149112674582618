<template>
  <div class="prose dark:prose-invert px-5 py-8">
    <h1 v-text="t('headline.welcome', { name: order.contact.name })" />

    <div
      v-html="
        ct('BOOKING_EVENT_BIRTHDAY_THANKYOU_INTRO', {
          cinema: order.cinema.name,
        })
      "
    />

    <h2 v-text="t('headline.bookingDetails')" />

    <dl class="grid grid-cols-2">
      <dt v-text="t('label.orderNumber')" />
      <dd>{{ order.orderNumber }}</dd>
      <dt v-text="t('label.date')" />
      <i18n-d
        tag="dd"
        :value="new Date(order.eventTimeSlots[0].eventTimeSlot.date)"
        :format="{
          weekday: 'long',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }"
      />
      <dt v-text="t('label.timeSlot')" />
      <dd v-text="timeSlot" />
      <dt v-text="t('label.numberOfChildren')" />
      <dd>
        {{ order.eventTimeSlots[0].numberOfPeople }} {{ t('kids') }}
        <template v-if="order.eventTimeSlots[0].numberOfCompanions > 0">
          / {{ order.eventTimeSlots[0].numberOfCompanions }}
          {{ t('companions') }}
        </template>
      </dd>
      <template v-if="wishFilm">
        <dt v-text="t('label.wishFilm')" />
        <dd v-text="wishFilm" />
      </template>
      <template v-if="message">
        <dt v-text="t('label.messageToTheCinema')" />
        <dd v-text="message" />
      </template>
    </dl>

    <h2 v-text="t('headline.snackPackages')" />

    <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
      <div
        v-for="({ quantity, concession, codes }, index) in order.concessions"
        :key="index"
      >
        {{ quantity }}x {{ concession.name }} ({{
          t('amountEach', {
            amount: n(concession.price.total, 'currency'),
          })
        }})

        <QrCode
          v-for="{ code, image } in codes"
          :key="code"
          :image="image"
          :code="code"
        />
      </div>
    </div>

    <p class="border-neutral border-t text-right font-bold">
      {{ t('total') }}: {{ n(order.originalPrice.total, 'currency') }}
    </p>

    <div v-if="order.code">
      <p v-text="t('qrCodeHelpText')" />
      <QrCode :code="order.code.code" :image="order.code.image" />
    </div>

    <div v-html="ct('BOOKING_EVENT_BIRTHDAY_THANKYOU_NOTE')" />

    <div
      v-html="
        ct('BOOKING_EVENT_BIRTHDAY_THANKYOU_CONTACT', {
          link: contactLink,
        })
      "
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  order: any
}

const { ct, fetchCinemaStrings } = useCinemaStrings()
const { t, n } = useI18n()

const props = defineProps<Props>()

await fetchCinemaStrings({
  cinemaId: props.order.cinema.id,
  keys: [
    'BOOKING_EVENT_BIRTHDAY_THANKYOU_INTRO',
    'BOOKING_EVENT_BIRTHDAY_THANKYOU_CONTACT',
    'BOOKING_EVENT_BIRTHDAY_THANKYOU_NOTE',
  ],
})

const timeSlot = computed(() => {
  const { from = '', until = '' } =
    props.order.eventTimeSlots?.[0]?.eventTimeSlot

  return [from, until]
    .filter((val) => val)
    .map((val) => val.split(':').slice(0, 2).join(':'))
    .join(' - ')
})

const wishFilm = computed(
  () => props.order.contact?.movieRequest?.split('|')?.[0] ?? '-'
)
const message = computed(
  () => props.order.contact?.movieRequest?.split('|')?.[1] ?? '-'
)

const contactLink = computed(
  () =>
    `<a href="${props.order.cinema.url}" class="inline" target="${LINK_TARGET.BLANK}" rel="${LINK_REL.NOOPENER} ${LINK_REL.NOFOLLOW}">${props.order.cinema.url}</a>`
)

defineOptions({
  name: 'OrderEvent',
})
</script>

<i18n>
de:
  kids: "Kinder"
  companions: "Begleiter"
  amountEach: "je {amount}"
  total: "Gesamtpreis"
  qrCodeHelpText: "Bringen Sie folgenden Code entweder ausgedruckt oder direkt auf Ihrem Handy ins Kino mit:"
  headline:
    welcome: "Guten Tag {name}"
    bookingDetails: "Buchungseingaben"
    snackPackages: "Snackpakete & Extras"
  label:
    orderNumber: "Bestellnummer"
    date: "Datum"
    timeSlot: "Zeitslot"
    numberOfChildren: "Anzahl Kinder"
    wishFilm: "Wunschfilm"
    messageToTheCinema: "Nachricht ans Kino"
</i18n>
