<template>
  <PageContainer class="flex-grow py-8">
    <PageGrid class="h-full">
      <PageGridColumn class="space-y-4 md:w-1/2">
        <AuthResetPasswordSuccess
          v-if="state === RESET_PASSWORD_SUCCESS"
          :with-link="true"
        />

        <AuthResetPasswordNew
          v-else-if="token && email && state === NEW_PASSWORD"
          :email="email"
          :token="token"
        />

        <div v-else>
          <span v-text="t('invalidLink')" />
        </div>
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const { t } = useI18n()
const { state, changeState } = useAuthState()

const token = useRouteQuery('token') as unknown as Ref<string>
const email = useRouteQuery('email') as unknown as Ref<string>

const { NEW_PASSWORD, RESET_PASSWORD_SUCCESS } = USER_AUTH_STATE

changeState(NEW_PASSWORD)

usePageMetas({
  title: t('newPassword'),
  description: t('newPassword'),
})

defineI18nRoute({
  paths: {
    de: '/konto/passwort/neu',
    es: '/cuenta/contrasena/nueva',
  },
})

defineOptions({
  name: 'AccountPasswordNewPage',
})
</script>

<i18n>
de:
  invalidLink: "Der Link ist ungültig. Bitte verwende die Funktion „Passwort vergessen“, um es erneut zu versuchen."
  newPassword: "Neues Passwort"
es:
  invalidLink: "El enlace no es válido. Utiliza la función de '¿Olvidaste tu contraseña?' para intentarlo de nuevo."
  newPassword: "Nueva contraseña"
</i18n>
