export const USER_AUTH_STATE = {
  LOGIN: 'login',
  REGISTER: 'register',
  REGISTER_ACCOUNT_CREATED: 'register-account-created',
  REGISTER_SUCCESS: 'register-success',
  RESET_PASSWORD: 'reset-password',
  RESET_PASSWORD_CONFIRMATION: 'reset-password-confirmation',
  RESET_PASSWORD_SUCCESS: 'reset-password-success',
  NEW_PASSWORD: 'new-password',
} as const
