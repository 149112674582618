export default function useCurrentUrl() {
  const nuxtApp = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()
  const currentUrl = ref('')
  const currentHost = ref('')

  if (process.client) {
    currentHost.value =
      (runtimeConfig.public.SITE_URL as string) ?? window.location.origin
    currentUrl.value = `${currentHost.value}${window.location.pathname}`
  }

  if (process.server) {
    const req = nuxtApp.ssrContext?.event.node.req
    const protocol = req?.headers['x-forwarded-proto'] ? 'https' : 'http'

    currentHost.value =
      (runtimeConfig.public.SITE_URL as string) ??
      `${protocol}://${req?.headers.host}`
    currentUrl.value = `${currentHost.value}${req?.url}`
  }

  const currentDomain = computed(() => new URL(currentUrl.value).hostname)

  return {
    currentUrl,
    currentHost,
    currentDomain,
  }
}
