declare global {
  interface Window {
    kinoheldEframe: {
      init(options: EframeOptions): void
    }
  }
}

export default function useLegacyWidget(
  options: EframeOptions = { iframeName: 'kinoheld-widget' }
) {
  const runtimeConfig = useRuntimeConfig()
  const appConfig = useAppConfig()
  const affiliateRef = useAffiliateState()
  const referrer = useReferrerState()
  const utm = useUtmState()
  const localeRoute = useLocaleRoute()
  const { locale } = useI18n()
  const baseUrl = runtimeConfig.public.WIDGET_BASE_URL
  const scriptUrl = `${baseUrl}/dist/eframe/eframe.min.js`

  const { design } = useDesign()
  const eframeOptions: EframeOptions = {
    ...options,
    design: design.value?.legacyDesign as string,
    noChange: true,
    ref: appConfig.affiliateOverride ?? affiliateRef.value?.key,
    referrer: referrer.value,
    stickyHeader: '#header',
    utm_source: utm.value?.utm_source,
    utm_medium: utm.value?.utm_medium,
    utm_campaign: utm.value?.utm_campaign,
  }

  const { load, unload } = useScriptTag(scriptUrl, initWidget, { manual: true })

  async function onCheckout({
    orderNumber,
    securityKey,
  }: {
    orderNumber: string
    securityKey: string
  }) {
    const route = localeRoute(
      {
        name: ROUTE_NAMES.ORDER,
        params: {
          orderNumber,
          securityKey,
        },
      },
      locale.value
    )

    await navigateTo(route)
  }

  onMounted(async () => {
    await load()
  })

  onBeforeUnmount(async () => {
    await unload()
  })

  function initWidget() {
    if (document.getElementById(options.iframeName)) {
      return
    }

    window.kinoheldEframe.init({
      baseUrl,
      lang: locale.value,
      ...eframeOptions,
      onCheckout,
    })
  }
}
