import type { Design } from '#gql/default'

/**
 * Generates CSS background styles string from Design background object
 * @param background - The background configuration object
 * @returns CSS style string for background
 */
export default function getBackgroundStyles(
  background?: Design['background']
): string {
  if (!background) {
    return ''
  }

  return [
    `background-position: ${background.position}`,
    `background-size: ${background.size}`,
    `background-repeat: ${background.repeat}`,
  ].join(';')
}
