<template>
  <div class="inline-block text-center">
    <div class="border-neutral bg-light inline-block rounded-lg border p-4">
      <img :src="image" class="m-0" :alt="t('altText')" />
    </div>
    <div class="text-xs leading-none" v-text="code" />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  image: string
  code: string
}

defineProps<Props>()
const { t } = useI18n()

defineOptions({
  name: 'QrCode',
})
</script>

<i18n>
de:
  altText: "QR-Code mit Link zu externen Inhalten."
</i18n>
