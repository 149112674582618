/**
 * Extracts RGB color values from a CSS variable string
 * @param text - The CSS text containing color variables
 * @param variableName - The name of the CSS variable to extract
 * @returns Array of RGB values or null if not found
 */
export default function extractColorValue(
  text: string,
  variableName: string
): number[] | null {
  const regex = new RegExp(`${variableName}:\\s*(\\d+)\\s*(\\d+)\\s*(\\d+);`)
  const match = text.match(regex)

  if (!match) {
    return null
  }

  return match.slice(1, 4).map((value) => parseInt(value, 10))
}
