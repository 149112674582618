export const LINK_TARGET = {
  TOP: '_top',
  SELF: '_self',
  BLANK: '_blank',
} as const

export const LINK_REL = {
  NOOPENER: 'noopener',
  NOREFERRER: 'noreferrer',
  NOFOLLOW: 'nofollow',
}
