import type { City } from '#gql/default'

export const useCinemaStringsState = () =>
  useState<{ [key: string]: string } | {}>('cinemaStrings', () => ({}))
export const useConsentState = () =>
  useState<string[] | undefined>('userConsent', undefined)
export const useConsentModalState = () =>
  useState('consentManagerModals', () => ({
    isSettingsModalVisible: false,
    isInitialModalVisible: true,
  }))
export const useCityState = () =>
  useState<{ [blockId: string]: City }>('city', () => ({}))

export const useMenuState = () =>
  useState('menu', () => ({
    isOpen: false,
    subMenuId: '',
  }))

export const useHeaderScrollState = () =>
  useState('moviePageNavigation', () => ({
    activeSection: null,
  }))

export const useAffiliateState = () =>
  useState<{ id: string; key: string } | undefined>('affiliate', undefined)
export const useReferrerState = () =>
  useState<string | undefined>('referrer', undefined)
export const useUtmState = () =>
  useState<
    | {
        utm_source?: string
        utm_medium?: string
        utm_campaign?: string
      }
    | undefined
  >('utm', undefined)

export const useMessageState = () => useState<Message[]>('messages', () => [])
