import resolve from '@base/utils/resolve'
import type { Image } from '@base/types/image'
import { IMAGE_FORMAT } from '@base/constants/image'

export default function getImage(
  entity: any,
  imageName: string,
  altText?: string
): ImageObject {
  const image = resolve(entity, imageName)

  function getImageFormat(image: Image) {
    const { width, height } = image

    if (width / height > 1.77) {
      return IMAGE_FORMAT.WIDESCREEN
    } else if (width > height) {
      return IMAGE_FORMAT.LANDSCAPE
    } else if (width < height) {
      return IMAGE_FORMAT.PORTRAIT
    } else {
      return IMAGE_FORMAT.SQUARE
    }
  }

  return {
    colors: image?.colors ?? [],
    src: image?.url,
    alt: image?.url ? altText : '',
    width: image ? image.width : undefined,
    height: image ? image.height : undefined,
    format: image ? getImageFormat(image) : undefined,
  }
}
