<template>
  <PageContainer class="flex-grow py-8">
    <PageGrid class="h-full">
      <PageGridColumn
        v-if="status === 'success' && data?.accountActivate?.user"
      >
        <AuthRegisterAccountConfirmed :with-link="true" />
      </PageGridColumn>

      <PageGridColumn
        v-else
        class="flex h-full w-full flex-grow flex-col items-center justify-center"
      >
        <LoadingIndicator v-if="status === PENDING" class="size-20" />

        <span v-if="showInvalidLink" v-text="t('invalidLink')" />

        <span
          v-if="status === ERROR"
          v-text="error?.statusMessage ?? t('defaultError')"
        />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const { t } = useI18n()
const { PENDING, ERROR } = GQL_STATUS_VALUES

const token = useRouteQuery('activation_key') as unknown as Ref<string>
const email = useRouteQuery('email') as unknown as Ref<string>

const showInvalidLink = ref(false)

const { data, error, execute, status } = useAsyncGql(
  'AccountActivate',
  {
    token: token.value,
    email: email.value,
  },
  { immediate: false }
)

if (token.value && email.value) {
  await execute()
} else {
  showInvalidLink.value = true
}

usePageMetas({
  title: t('activateAccount'),
  description: t('activateAccount'),
  jsonLD: null,
  twitterTags: [],
  openGraphTags: [],
})

defineI18nRoute({
  paths: {
    de: '/konto/aktivieren',
    es: '/cuenta/activar',
  },
})

defineOptions({
  name: 'AccountActivatePage',
})
</script>

<i18n>
de:
  invalidLink: "Der Link ist ungültig."
  activateAccount: "Konto aktivieren"
  defaultError: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."
es:
  invalidLink: "El enlace no es válido."
  activateAccount: "Activar cuenta"
  defaultError: "Se ha producido un error. Por favor, inténtalo de nuevo más tarde."
</i18n>
