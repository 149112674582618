export const PAGE_CONTAINER_TAG = {
  DIV: 'div',
  NAV: 'nav',
  SECTION: 'section',
  ARTICLE: 'article',
  HEADER: 'header',
  FOOTER: 'footer',
} as const

export const PAGE_CONTAINER_HEIGHT = {
  FLEXIBLE: 'flexible',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const
